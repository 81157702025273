<template>
    <div style="background-color: #141618;" class="py-16 md:py-32">
        <div class="container text-center px-6 mx-auto">
            <h1 class="text-gray-100 text-3xl md:text-5xl font-bold pb-8">Global partners</h1>
            <p class="text-gray-500 tracking-widest text-sm md:text-lg lg:max-w-4xl leading-10 mx-auto font-semibold">Wij hebben een samenwerking met verschillende partners.</p>
  
        <ul class="flex flex-wrap max-w-6xl items-center justify-center gap-8 mx-auto">
  <li>
    <img src="https://trustcleanwithcare.nl/img/logo.3f070c39.png" alt="Trust Clean with Care logo" height="150" width="150">
  </li>
  <li>
    <img src="https://trustwebdesign.nl/assets/logo.png" alt="Trust Web Design logo" height="150" width="150">
  </li>
</ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Sponsorship',
        data() {
            return {
                icons: [
                    'audi', 'honda', 'lexus', 'tango', 'tata-indicom', 'techcrunch', 'tesla', 'volkswagen'
                ]
            }
        },
    }
</script>

<style scoped>

</style>