<template>
    <div >

    </div>
</template>

<script>
    export default {
        name: 'InformationTwo',
        data() {
            return {
                images: 'https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1000',
                items: [
                    "Premium selection",
                    "Insurance",
                    "All legal documents",
                    "Fast shipping (+ Express)"
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>