<template>
    <div class="dark:bg-gray-800 border-t lg:border-none">
        <div class="container mx-auto px-6">
            <div class="py-8 md:py-16">
                <div style="max-width: 1200px" class="flex flex-col mx-auto space-y-6 lg:py-16 lg:flex-row lg:items-center lg:space-x-6">    
                    <div class="w-full sm:mb-10 lg:mb-0">
                        <div class="lg:pr-16">
                            <h2 class="text-xs pb-4 uppercase font-bold tracking-wide text-indigo-400 dark:text-white">MoparExpressNV</h2>
                            <h1 class="text-2xl font-semibold tracking-wide text-gray-500 dark:text-white lg:text-3xl">Welkom bij MoparExpressNV</h1>
                            <p class="mt-8 text-gray-400 text-md">MoparExpressNV is een jong en dynamisch bedrijf dat zich bezighoudt met import en export van verschillende goederen en producten. Het bedrijf is opgericht in 2017 en heeft sindsdien een sterke reputatie opgebouwd op het gebied van betrouwbaarheid, kwaliteit en klantenservice.</p>

 <p class="mt-8 text-gray-400 text-md">Bij MoparExpressNV staan klanten centraal. Het bedrijf streeft ernaar om aan de behoeften en verwachtingen van klanten te voldoen door het bieden van kwalitatief hoogwaardige producten en diensten. Het bedrijf werkt samen met betrouwbare partners en leveranciers over de hele wereld om ervoor te zorgen dat de producten die worden aangeboden aan klanten van de beste kwaliteit zijn en tegen concurrerende prijzen.</p>

 <p class="mt-8 text-gray-400 text-md">MoparExpressNV is gespecialiseerd in de import en export van een breed scala aan producten, waaronder auto-onderdelen, machines, elektronica, en veel meer. Het bedrijf heeft een grondige kennis van de markt en beschikt over de nodige expertise om klanten te helpen bij het vinden van de beste producten die aansluiten op hun behoeften en budget.</p>

 <p class="mt-8 text-gray-400 text-md">Met een toegewijd team van professionals en een sterke focus op klanttevredenheid, is MoparExpressNV een betrouwbare partner voor bedrijven en particulieren die op zoek zijn naar import en export diensten van hoge kwaliteit.</p>
                        </div>
                    </div>
                    <div class="flex sm:border border-dotted lg:max-w-lg items-center sm:relative justify-center w-full h-96">
                        <img style="max-width: 460px; height: 80%;" class="object-cover shadow-lg sm:absolute -top-7 w-full h-full rounded-md" src="../../assets/images/cargo.jpg">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Information',
        data() {
            return {
                images: [
                    'https://www.freepik.com/free-vector/import-export-infographic-hand-drawn_20058575.htm#query=import%20export&position=2&from_view=search&track=ais0',
                
                ]
            }
        },
    }
</script>
