<template>
    <div class="_group_six_temp py-16 container xl:px-10 px-6 mx-auto lg:py-32 lg:flex justify-center flex-col">
        <h1 class="font-bold lg:pb-10 text-center text-gray-900 text-5xl pb-4">{{title}}</h1>
        <p class="text-md lg:pb-10 max-w-5xl mx-auto text-center text-gray-500 pb-8">{{paragraph}}</p>
        <hr class="w-full hidden lg:block max-w-lg mx-auto">
        <div class="grid lg:pt-20 text-center lg:text-left md:16 gap-6 md:grid-cols-2 lg:gap-x-14 lg:grid-cols-3 ">
            <div class="p-2" v-for="(item, index) in items" :key="index">
                <h1 class="font-bold text-gray-900 text-xl pb-3">{{item.title}}</h1>
                <p class=" text-sm text-gray-500">{{item.paragraph}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OurServices',
        data() {
            return {
                ss: '<h1>hh</h1>',
                title: 'Waarom MoparexpressNV',
                paragraph: 'Jouw betrouwbare partner voor naadloze import- en exportoplossingen.',
                items: [
                    {
                        title: 'Ervaring en expertise',
                        paragraph: 'MoparExpressNV heeft sinds de oprichting in 2017 veel ervaring opgebouwd in de import en export van verschillende producten. Het bedrijf beschikt over de nodige kennis en expertise om klanten te helpen bij het vinden van de beste oplossingen voor hun behoeften.'
                    },
                    {
                        title: 'Kwaliteit',
                        paragraph: 'MoparExpressNV werkt samen met betrouwbare leveranciers over de hele wereld om ervoor te zorgen dat de producten van de hoogste kwaliteit zijn. Het bedrijf streeft ernaar om klanten te voorzien van de beste producten die voldoen aan hun specifieke behoeften.'
                    },
                    {
                        title: 'Klantenservice',
                        paragraph: ' Klanttevredenheid staat centraal bij MoparExpressNV. Het bedrijf streeft naar een hoog niveau van klantenservice en biedt ondersteuning bij elke stap van het import- of exportproces.'
                    },
                    {
                        title: 'Concurrerende prijzen',
                        paragraph: 'MoparExpressNV werkt hard om ervoor te zorgen dat klanten concurrerende prijzen krijgen voor de producten die zij importeren of exporteren. Het bedrijf biedt transparante prijzen en werkt samen met klanten om oplossingen te vinden die binnen hun budget passen.'
                    },
                    {
                        title: 'Breed scala aan producten',
                        paragraph: 'MoparExpressNV is gespecialiseerd in de import en export van een breed scala aan producten, van auto-onderdelen tot machines en elektronica. Het bedrijf kan klanten helpen bij het vinden van de beste producten voor hun specifieke behoeften.'
                    },
                    {
                        title: 'Betrouwbare partner',
                        paragraph: 'MoparExpressNV is een betrouwbare partner voor bedrijven en particulieren die import- of exportdiensten nodig hebben. Het bedrijf streeft ernaar om langdurige relaties op te bouwen met klanten en is bereid om samen te werken aan oplossingen die aan hun behoeften voldoen.'
                    }
                ]
            }
        },
    }
</script>

<style  scoped>
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;600&display=swap');

    ._group_six_temp {
        min-height: 80vh;
    }
    ._group_six_temp h1, p {
        font-family: 'Inter', sans-serif;
    }
</style>